
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'OtherNote',
    props: {
        mail: {
            type: String,
            default: "",
            require: true
        }
    },
    computed: {
        mailToLink(): string {
            return "mailto:" + this.mail
        }
    }
})
