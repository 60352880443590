
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WebTitle',
  props: {
    title: {
      type: String,
      default: '鹅鸭杀',
      require: true
    }
  },
  created() {
    document.title = this.title;
  },
})
