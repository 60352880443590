import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WebTitle = _resolveComponent("WebTitle")!
  const _component_DomainRow = _resolveComponent("DomainRow")!
  const _component_OtherNote = _resolveComponent("OtherNote")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_WebTitle, { title: "鹅鸭杀" }),
    _createVNode(_component_DomainRow),
    _createVNode(_component_OtherNote, { mail: "nopdelay@outlook.com" })
  ]))
}