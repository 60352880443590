
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DomainRow',
  computed: {
    mallAddr(): string {
      return "https://mi.aliyun.com/detail/online.html?domainName=" + this.hostName.toLowerCase();
    },
    hostName(): string {
      return window.location.host.toUpperCase();
    }
  },
})
