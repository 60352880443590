
import { Options, Vue } from 'vue-class-component';
import DomainRow from './components/DomainRow.vue';
import WebTitle from './components/WebTitle.vue';
import OtherNote from './components/OtherNote.vue';
console.log(window.location.origin)

@Options({
  components: {
    DomainRow,
    WebTitle,
    OtherNote,
  },
})
export default class App extends Vue { }
